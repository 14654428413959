import React from "react"

const Greeting = () => {
  const greet = () => {
    const now = new Date()
    const currentHour = now.getHours()

    if (currentHour >= 12 && currentHour < 18) {
      return "afternoon"
    } else if (currentHour >= 18) {
      return "evening"
    }

    return "morning"
  }

  return <>Good {greet()}</>
}

export default Greeting
