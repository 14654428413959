import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Cycler } from "react-text-scrambler"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Separator from "../components/separator"
import Container from "../components/container"
import Greeting from "../components/greeting"

const LogoContainer = styled.div`
  padding: 70px;
  min-height: calc(100vh - 140px);

  .hello {
    font-size: 12rem;
    font-weight: 800;
    opacity: 0.1;
    position: relative;
    top: -190px;
    left: -10px;
    line-height: 0;
    display: block;
    margin-bottom: -30px;
  }

  .intro {
    display: block;
    margin-top: 150px;
    font-size: 3rem;
    font-weight: 800;
    line-height: 3.5rem;
  }

  h1 {
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 400;
    max-width: 800px;
    margin-top: 10px;
  }

  a {
    display: inline-block;
    margin-bottom: 125px;

    img {
      max-width: 60px;
    }
  }
`

const DarkSeparator = styled(Separator)`
  background: #000;
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Codespire - Bespoke Software, Web & Mobile Application Developer"
      disableTemplate={true}
    />

    <LogoContainer>
      <Container>
        <Link to="/">
          <img src={require("../images/logo.png")} />
        </Link>

        <div>
          <span className="intro">
            <Greeting />, we are Codespire.
          </span>
          <h1>
            Codespire is a software and digital product development studio,
            helping companies to grow, and get ahead of their competition in an
            ever-changing digital world.
          </h1>

          <span className="hello">
            <Cycler
              typewriter={false}
              strings={["Bonjour", "Hola", "Guten tag", "Hello", "Buonguiorno"]}
              duration={3000}
              delay={3000}
              startDelay={0}
              humanLike={false}
              characters="QXqo,D2<!A,!6iFwn"
            />
          </span>
        </div>
        <DarkSeparator />
      </Container>
    </LogoContainer>
  </Layout>
)

export default IndexPage
